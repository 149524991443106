import React from "react";

const ImportIcon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.75 9.1875C21.9563 9.1875 22.125 9.01875 22.125 8.8125V4.5C22.125 4.29375 21.9563 4.125 21.75 4.125H17.4375C17.2312 4.125 17.0625 4.29375 17.0625 4.5V5.8125H6.9375V4.5C6.9375 4.29375 6.76875 4.125 6.5625 4.125H2.25C2.04375 4.125 1.875 4.29375 1.875 4.5V8.8125C1.875 9.01875 2.04375 9.1875 2.25 9.1875H3.5625V14.8125H2.25C2.04375 14.8125 1.875 14.9812 1.875 15.1875V19.5C1.875 19.7063 2.04375 19.875 2.25 19.875H6.5625C6.76875 19.875 6.9375 19.7063 6.9375 19.5V18.1875H17.0625V19.5C17.0625 19.7063 17.2312 19.875 17.4375 19.875H21.75C21.9563 19.875 22.125 19.7063 22.125 19.5V15.1875C22.125 14.9812 21.9563 14.8125 21.75 14.8125H20.4375V9.1875H21.75ZM18.5625 5.625H20.625V7.6875H18.5625V5.625ZM3.375 7.6875V5.625H5.4375V7.6875H3.375ZM5.4375 18.375H3.375V16.3125H5.4375V18.375ZM20.625 16.3125V18.375H18.5625V16.3125H20.625ZM18.75 14.8125H17.4375C17.2312 14.8125 17.0625 14.9812 17.0625 15.1875V16.5H6.9375V15.1875C6.9375 14.9812 6.76875 14.8125 6.5625 14.8125H5.25V9.1875H6.5625C6.76875 9.1875 6.9375 9.01875 6.9375 8.8125V7.5H17.0625V8.8125C17.0625 9.01875 17.2312 9.1875 17.4375 9.1875H18.75V14.8125Z"
      fill={fill}
      // fill-opacity="0.45"
    />
  </svg>
);

export default ImportIcon;
