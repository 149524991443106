import React from "react";

const ImportIcon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.125 7.3125V6.1875C16.125 6.08437 16.0406 6 15.9375 6H6.9375C6.83437 6 6.75 6.08437 6.75 6.1875V7.3125C6.75 7.41563 6.83437 7.5 6.9375 7.5H15.9375C16.0406 7.5 16.125 7.41563 16.125 7.3125ZM6.9375 9.375C6.83437 9.375 6.75 9.45937 6.75 9.5625V10.6875C6.75 10.7906 6.83437 10.875 6.9375 10.875H11.25C11.3531 10.875 11.4375 10.7906 11.4375 10.6875V9.5625C11.4375 9.45937 11.3531 9.375 11.25 9.375H6.9375ZM10.3125 19.9688H4.875V3.46875H18V11.5312C18 11.6344 18.0844 11.7188 18.1875 11.7188H19.5C19.6031 11.7188 19.6875 11.6344 19.6875 11.5312V2.53125C19.6875 2.11641 19.3523 1.78125 18.9375 1.78125H3.9375C3.52266 1.78125 3.1875 2.11641 3.1875 2.53125V20.9062C3.1875 21.3211 3.52266 21.6562 3.9375 21.6562H10.3125C10.4156 21.6562 10.5 21.5719 10.5 21.4688V20.1562C10.5 20.0531 10.4156 19.9688 10.3125 19.9688ZM20.7586 21.1758L18.5719 18.9891C19.0945 18.2977 19.4062 17.4352 19.4062 16.5C19.4062 14.2219 17.5594 12.375 15.2812 12.375C13.0031 12.375 11.1562 14.2219 11.1562 16.5C11.1562 18.7781 13.0031 20.625 15.2812 20.625C16.1203 20.625 16.8984 20.3742 17.55 19.9453L19.7695 22.1648C19.807 22.2023 19.8539 22.2188 19.9008 22.2188C19.9477 22.2188 19.9969 22.2 20.032 22.1648L20.7586 21.4383C20.7759 21.4211 20.7896 21.4006 20.799 21.3781C20.8083 21.3556 20.8132 21.3314 20.8132 21.307C20.8132 21.2826 20.8083 21.2585 20.799 21.236C20.7896 21.2134 20.7759 21.193 20.7586 21.1758V21.1758ZM15.2812 19.125C13.8305 19.125 12.6562 17.9508 12.6562 16.5C12.6562 15.0492 13.8305 13.875 15.2812 13.875C16.732 13.875 17.9062 15.0492 17.9062 16.5C17.9062 17.9508 16.732 19.125 15.2812 19.125Z"
      fill={fill}
      //   fill-opacity="0.45"
    />
  </svg>
);

export default ImportIcon;
