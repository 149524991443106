import React from "react";

const ImportIcon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8195 17.7516H19.5586C19.4602 17.7516 19.3781 17.8336 19.3781 17.932V19.3805H4.61953V4.61953H19.3805V6.06797C19.3805 6.16641 19.4625 6.24844 19.5609 6.24844H20.8219C20.9203 6.24844 21.0023 6.16875 21.0023 6.06797V3.71953C21.0023 3.32109 20.6813 3 20.2828 3H3.71953C3.32109 3 3 3.32109 3 3.71953V20.2805C3 20.6789 3.32109 21 3.71953 21H20.2805C20.6789 21 21 20.6789 21 20.2805V17.932C21 17.8312 20.918 17.7516 20.8195 17.7516ZM21.1406 11.1562H13.7812V9.375C13.7812 9.21797 13.5984 9.12891 13.4766 9.22734L10.1508 11.8523C10.1284 11.8699 10.1102 11.8923 10.0978 11.9179C10.0853 11.9435 10.0788 11.9715 10.0788 12C10.0788 12.0285 10.0853 12.0565 10.0978 12.0821C10.1102 12.1077 10.1284 12.1301 10.1508 12.1477L13.4766 14.7727C13.6008 14.8711 13.7812 14.782 13.7812 14.625V12.8438H21.1406C21.2438 12.8438 21.3281 12.7594 21.3281 12.6562V11.3438C21.3281 11.2406 21.2438 11.1562 21.1406 11.1562Z"
      fill={fill}
      // fill-opacity="0.45"
    />
  </svg>
);

export default ImportIcon;
