import { graphql } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/layout";
import LogoMarquee from "../components/LogoMarquee";
import SEO from "../components/seo";
import "../styles/page/new_annotation.scss";
import CustomButton from "../components/CustomButton";
import VideoComponent from "../components/VideoComponent";
import DemoModal from "../components/DemoModal";
import { pickSlice } from "../utilities/helpers";
import AnnotateG2Review from "../components/newAnnotate/AnnotateG2Review";
import VerticalCard from "../components/newAnnotate/VerticalCard";
import EncordAnnotateProduct from "../components/newAnnotate/EncordAnnotateProducts";
import AnnotateCTACard from "../components/newAnnotate/AnnotateCTACard";
import AnnotateFooter from "../components/newAnnotate/AnnotateFooter";
import AnnotationToolsSection from "../components/newAnnotate/AnnotationTools";
import AnnotationAccelrate from "../components/newAnnotate/AnnotationAccelrate";
import CustomerStories from "../components/newAnnotate/CustomerStories";
import NewAnnotateTypes from "../components/newAnnotate/newAnnotationTypes";
import HorizontalCard from "../components/newAnnotate/HorizontalCard";
import graphicPoster from "../assets/new-home-page/bg_graphics_poster.jpg";
import bg_video from "../assets/new-home-page/bg_graphic_flow.webm";
import CardFlex from "../components/newAnnotate/CardFlex";
import AnnotateCaseStudyCard from "../components/newAnnotate/CaseStudyCard";
import WorkflowIcon1 from "../assets/icons/NewAnnotate/ImportIcon";
import WorkflowIcon2 from "../assets/icons/NewAnnotate/LabelIcon";
import WorkflowIcon3 from "../assets/icons/NewAnnotate/ReviewIcon";

const MultiModalPage = ({ data, location }) => {
  const _data = data?.prismicAnnotateV3?.data || {};
  const logosData = data.prismicTrustedBrandsLogos?.data;
  const [trusted_brands_list] = pickSlice(logosData, "trusted_brands_list");
  const [case_study_cards] = pickSlice(_data, "case_study_cards");
  const [annotation_types] = pickSlice(_data, "annotation_types");
  const [contact_sales_quotes] = pickSlice(_data, "contact_sales_quotes");
  const [encord_other_products] = pickSlice(_data, "encord_other_products");
  const [customer_stories] = pickSlice(_data, "customer_stories");
  const [GridCards] = pickSlice(_data, "annotation_tools_grid_cards");
  const [page_section] = pickSlice(_data, "page_section_");
  const [annotation_tools_cards] = pickSlice(_data, "annotation_tools_cards");
  const [annotation_tools_section] = pickSlice(
    _data,
    "annotation_tools_section"
  );
  const [cta_card] = pickSlice(_data, "cta_card");
  const { encord_products = [[]] } = _data?.body?.reduce((acc, v) => {
    acc[v.slice_type] = v;
    return acc;
  }, {});

  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const VerticalCardIcons = [
    {
      icon: "https://encord.cdn.prismic.io/encord/fe00a610-84b5-4bed-987a-0fe349e61c74_productIcons.svg?fit=max",
    },
    {
      icon: "https://encord.cdn.prismic.io/encord/83549f38-37be-426c-a312-5107f575c736_testing.svg?fit=max",
    },
  ];

  return (
    <Layout mainClasses="" theme="newDefault">
      <VideoComponent
        autoPlay
        poster={graphicPoster}
        className="absolute left-0 right-0 z-0 [mix-blend-mode:darken] lg:top-20 sm:top-16 hidden lg:block"
      >
        <source
          className="row-start-1 row-end-4"
          src={bg_video}
          type="video/webm"
        />
      </VideoComponent>
      <article className="relative px-4 pt-32 mx-auto sm:px-5 max-w-7xl md:pt-44">
        <section>
          <section className="w-full max-w-4xl mx-auto text-center">
            <p className="text-xs font-semibold uppercase sm:font-medium sm:text-base text-purple-1500 tracking-[1.2px] sm:tracking-widest_1 ">
              {_data?.page_label?.text}
            </p>
            <div
              dangerouslySetInnerHTML={{ __html: _data?.heading?.html }}
              className={`text-gray-2700 strong_Annotate  lg:max-w-[678px] tracking-[-2.4px] mx-auto font-manrope mt-3.5 text-2.5xl text-center sm:leading-tighter-13 sm:text-4.5xl font-bold mb-5`}
            />

            <p className="lg:max-w-[754px] sm:mx-12 lg:!mx-auto mb-5 text-sm text-center text-gray-600 sm:text-lg lg:text-xl md:mb-9 tracking-tight_1">
              {_data?.description?.text}
            </p>

            <section className="flex justify-center">
              <CustomButton
                text="Get started"
                buttonClass="bg-purple-1500 hover:bg-[#5E24FF] text-white"
                onClick={handleModal}
              />
            </section>
          </section>
          <section className="hidden pt-12 img_grayscale_opacity branding_loop_width lg:block">
            <p className="text-xs font-manrope font-semibold text-center uppercase tracking-widest_1 text-gray-600 -mb-0.5 lg:mb-1">
              {trusted_brands_list?.primary?.label?.text}
            </p>
            <LogoMarquee logoArray={trusted_brands_list} logoBackground="" />
          </section>

          {(_data?.hero_video?.url || _data?.hero_image?.url) && (
            <section className="hidden mt-10 bg-white border border-gray-1600 sm:py-5 rounded-2xl sm:rounded-2.5xl lg:py-12 sm:flex sm:px-5 lg:px-10 lg:mx-11">
              {_data?.hero_video?.url ? (
                <div>
                  <VideoComponent
                    loop
                    autoPlay
                    muted
                    className="w-full h-auto lg-max-w-[1072px] mx-auto rounded-xl sm:rounded-2xl"
                  >
                    <source src={_data?.hero_video?.url} type="video/mp4" />
                  </VideoComponent>
                </div>
              ) : (
                <>
                  {_data?.hero_image?.url ? (
                    <img
                      src={_data?.hero_image?.url}
                      loading="eager"
                      width={_data?.hero_image?.dimensions?.width || 0}
                      height={_data?.hero_image?.dimensions?.height || 0}
                      alt="stats"
                      className="w-full h-auto max-w-4xl mx-auto md:mt-8 rounded-xl sm:rounded-2xl"
                    />
                  ) : (
                    <section className="w-full mt-20 bg-gray-200 2xl:mt-28 h-128" />
                  )}
                </>
              )}
            </section>
          )}
        </section>
        <section className="pt-10 pb-5 mx-auto lg:mt-10 sm:py-10 lg:mx-11">
          <div className="flex flex-col justify-center items-center gap-2.5 pb-10">
            <h1 className="font-manrope text-2xl sm:text-3.25xl font-semibold leading-10 text-purple-1100 -tracking-[1.8px]">
              {customer_stories?.primary?.section_heading?.text}
            </h1>
            <p className="text-sm sm:text-lg tracking-tighter_3 text-gray-2500 max-w-[770px] text-center">
              {customer_stories?.primary?.section_description?.text}
            </p>
          </div>
          <CustomerStories data={customer_stories?.items} />
        </section>
        {annotation_types && (
          <section className="rounded-2xl sm:rounded-2.5xl lg:mx-11 lg:mt-10">
            <NewAnnotateTypes annotationTypes={annotation_types} />
          </section>
        )}
        {annotation_tools_section && (
          <section className="py-12 bg-white rounded-2xl sm:rounded-2.5xl mt-5 sm:mt-10 lg:mx-11">
            <AnnotationToolsSection
              data={annotation_tools_section}
              openModal={handleModal}
            />
          </section>
        )}

        {annotation_tools_cards && (
          <section className="bg-white rounded-2xl sm:rounded-2.5xl mt-5 sm:mt-10 p-5 lg:p-10 lg:mx-11">
            <AnnotationAccelrate data={annotation_tools_cards} />
          </section>
        )}

        <section className="bg-white border my-5 sm:my-10 border-gray-1600 px-4 py-4 sm:p-6 lg:py-14 gap-6 lg:px-10 rounded-2xl sm:rounded-2.5xl lg:mx-11">
          <p className="text-xs font-medium uppercase sm:text-base text-purple-1500 tracking-widest_1">
            {encord_products?.primary?.section_label?.text}
          </p>
          <p className="mt-4 text-purple-1100 text-2xl sm:text-3.25xl font-manrope tracking-[-1.2px] sm:tracking-[-1.8px] font-semibold">
            {encord_products?.primary?.section_heading?.text}
          </p>
          <p className="mt-5 text-lg text-gray-600 tracking-tighter_3">
            {encord_products?.primary?.section_description?.text}
          </p>
          <section className="">
            <EncordAnnotateProduct
              MobileScreenLabel={`Encord workflow`}
              cardIcons={[
                {
                  icon: <WorkflowIcon1 />,
                },
                {
                  icon: <WorkflowIcon2 />,
                },
                {
                  icon: <WorkflowIcon3 />,
                },
                {
                  icon: <WorkflowIcon3 />,
                },
                {
                  icon: <WorkflowIcon3 />,
                },
              ]}
              DesktopScreenLabel={`Everything you can do with workflows`}
              encordProducts={encord_products}
            />
          </section>
        </section>
        {cta_card && (
          <section className="pb-5 sm:pb-10 lg:mx-11">
            <AnnotateCTACard data={cta_card?.primary} />
          </section>
        )}
        {GridCards && (
          <section className="pb-10 lg:pb-10 lg:mx-11">
            <CardFlex data={GridCards} />
          </section>
        )}
        {contact_sales_quotes && (
          <section className="flex justify-center p-4 sm:p-6 lg:p-10 my-5 sm:my-10 bg-gray-1600 rounded-2xl sm:rounded-2.5xl lg:mx-11">
            <section className="flex flex-col justify-center gap-6 sm:grid-flow-row sm:grid-cols-2 sm:grid ">
              {contact_sales_quotes?.items?.map((value, index) => (
                <AnnotateG2Review
                  key={index}
                  data={value}
                  CardClass={`${
                    index === 0
                      ? "px-9 py-6 lg:p-11 gap-0 bg-white"
                      : "px-9 py-11 lg:p-11"
                  }`}
                  main_Head_class={`${
                    index === 0
                      ? "!pt-8 font-medium tracking-widest_1 uppercase leading-6 text-base"
                      : "pt-0"
                  }`}
                  heading_class={`${
                    index == 0
                      ? " mt-3.5 sm:leading-9 sm:leading-10 !text-2.5xl lg:text-[36px] !font-bold"
                      : "-tracking-[1.2px] text-"
                  }`}
                  sub_heading={value.sub_heading}
                  sub_heading_class={`${
                    index == 0
                      ? "text-gray-1200 dark:!text-gray-1600 dark:opacity-60 mt-6 !min-h-[56px] tracking-tighter_3 text-lg lg:text-xl"
                      : "tracking-tighter_3 sm:text-base mt-6 text-gray-600 dark:text-gray-3000"
                  }`}
                  index={index}
                  LogoDivClass={`${index == 0 ? "mt-5 " : "mt-6"}`}
                />
              ))}
            </section>
          </section>
        )}
        {page_section && (
          <section className="pb-5 lg:mx-11 sm:pb-10 lg:pb-5">
            <HorizontalCard data={page_section} />
          </section>
        )}
        {case_study_cards && (
          <section className=" py-5 sm:py-10 rounded-2xl sm:rounded-2.5xl lg:mx-11">
            <AnnotateCaseStudyCard data={case_study_cards} />
          </section>
        )}
        {encord_other_products && (
          <>
            <section className="flex justify-center pt-5 sm:justify-start lg:mx-11 sm:pt-10">
              <p className=" text-purple-1100 tracking-tighter_3 !font-manrope text-2xl sm:text-3.25xl text-center sm:text-left font-semibold sm:tracking-[-1.8px] max-w-60 sm:max-w-full ">
                {encord_other_products?.primary?.section_heading?.text}
              </p>
            </section>

            <article className="py-5 sm:py-10 rounded-2xl sm:rounded-2.5xl lg:mx-11">
              <section className="flex flex-col gap-6 overflow-x-auto HideScrollBar scroll-smooth sm:flex-row">
                {encord_other_products?.items.map((data, index) => (
                  <VerticalCard
                    icon={VerticalCardIcons[index].icon}
                    data={data}
                    key={index}
                    descriptionClass={`min-h-[48px]`}
                    outlineColor={`text-purple-1500`}
                  />
                ))}
              </section>
            </article>
          </>
        )}
        <section className="lg:mx-11">
          <AnnotateFooter
            imageBG={true}
            heading={_data?.cta_heading?.text}
            description={_data?.cta_description?.text}
            openModal={handleModal}
          />
        </section>
      </article>

      <DemoModal
        title={`Get started`}
        isOpen={isOpen}
        closeModal={handleModal}
        location={location}
      />
    </Layout>
  );
};

export default MultiModalPage;

export const query = graphql`
  query {
    prismicAnnotateV3(uid: { eq: "multimodal" }) {
      data {
        cta_heading {
          text
          html
        }
        description {
          text
        }
        heading {
          html
          text
        }
        hero_image {
          alt
          url
        }
        hero_video {
          url
        }
        meta_description {
          text
        }
        meta_heading {
          text
        }
        page_label {
          text
        }
        body {
          ... on PrismicAnnotateV3DataBodyCustomerStories {
            id
            slice_type
            items {
              card_heading {
                html
                text
              }
              cta_link {
                url
              }
              cta_text {
                html
                text
              }
              icon {
                url
                alt
              }
            }
            primary {
              section_description {
                text
              }
              section_heading {
                text
                html
              }
            }
          }
          ... on PrismicAnnotateV3DataBodyAnnotationTypes {
            id
            slice_type
            primary {
              section_description {
                text
              }
              section_heading {
                html
                text
              }
              section_label {
                text
              }
            }
            items {
              label {
                text
              }
              coming_soon
              label_icon {
                url
                alt
              }
              label_slide_image {
                url
                alt
              }
              label_slide_video {
                url
              }
            }
          }
          ... on PrismicAnnotateV3DataBodyAnnotationToolsSection {
            id
            slice_type
            primary {
              section_label {
                text
              }
              section_heading {
                text
              }
              section_description {
                text
              }
              cta_text_2 {
                text
              }
              cta_text_1 {
                text
              }
              cta_link_2 {
                url
              }
              cta_link_1 {
                url
              }
            }
            items {
              slide_video {
                url
              }
              icon {
                url
                alt
              }
              slide_image {
                alt
                url
              }
            }
          }
          ... on PrismicAnnotateV3DataBodyAnnotationToolsCards {
            id
            slice_type
            items {
              card_video {
                url
              }
              card_image {
                alt
                url
              }
              card_icon {
                alt
                url
              }
              card_heading {
                text
              }
              card_description {
                text
              }
            }
          }
          ... on PrismicAnnotateV3DataBodyEncordProducts {
            id
            slice_type
            primary {
              section_label {
                text
              }
              section_heading {
                text
              }
              section_description {
                text
              }
            }
            items {
              product_video {
                url
              }
              product_sub_heading {
                text
              }
              product_image {
                url
                alt
              }
              product_icon {
                alt
                url
              }
              product_heading {
                text
              }
              product_description {
                text
              }
              cta_link {
                url
              }
            }
          }
          ... on PrismicAnnotateV3DataBodyPageSection {
            id
            slice_type
            items {
              section_image {
                url
                alt
              }
              section_video {
                url
              }
            }
            primary {
              label {
                text
              }
              section_description {
                text
              }
              section_heading {
                text
              }
            }
          }
          ... on PrismicAnnotateV3DataBodyCtaCard {
            id
            slice_type
            primary {
              cta_link {
                url
              }
              cta_text {
                text
              }
              reviewer_name {
                text
              }
              reviewer_role {
                text
              }
              section_description {
                text
              }
              section_heading {
                text
              }
              section_sub_heading {
                text
              }
            }
          }
          ... on PrismicAnnotateV3DataBodyAnnotationToolsGridCards {
            id
            slice_type
            items {
              card_description {
                text
              }
              card_heading {
                text
              }
              card_image {
                url
              }
              card_label {
                text
              }
              card_video {
                url
              }
              icon {
                url
              }
            }
          }
          ... on PrismicAnnotateV3DataBodyContactSalesQuotes {
            id
            slice_type
            items {
              author_name {
                text
              }
              label {
                text
              }
              review_description {
                text
              }
              review_heading {
                text
              }
            }
          }
          ... on PrismicAnnotateV3DataBodyCaseStudyCards {
            id
            slice_type
            items {
              link {
                url
              }
              icon {
                url
              }
              description1 {
                text
              }
              button_text {
                text
              }
            }
          }
          ... on PrismicAnnotateV3DataBodyEncordOtherProducts {
            id
            slice_type
            items {
              icon {
                url
              }
              cta_text {
                text
              }
              cta_link {
                url
              }
              card_label {
                text
              }
              card_image {
                url
              }
              card_heading {
                text
              }
              card_description {
                text
              }
            }
            primary {
              section_heading {
                text
              }
            }
          }
        }
        cta_description {
          text
        }
      }
    }
    prismicTrustedBrandsLogos(uid: { eq: "home-page-logos" }) {
      data {
        body {
          ... on PrismicTrustedBrandsLogosDataBodyTrustedBrandsList {
            id
            slice_type
            primary {
              label {
                html
                text
              }
            }
            items {
              brand_image {
                alt
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicAnnotateV3?.data || {};
  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};
